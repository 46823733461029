import { computed, inject, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";
import { VueCookieNext } from "vue-cookie-next";
import qs from "qs";
import useTools from "@/composables/General/useTools";

export default function useProvider() {
  const api = inject("$api");
  const {generateErrorMessage} = useTools();
  let provider = reactive({});
  const store = useStore();
  const route = useRoute();
  const toast = useToast();
  const { t } = useI18n();

  const load = () => {
    return new Promise((resolve, reject) => {
      if (store.getters.getProvider) {
        Object.assign(provider, store.getters.getProvider);
        return resolve(store.getters.getProvider);
      }

      return api
        .get("/provider")
        .then((response) => {
          if (response.status === 200) {
            Object.assign(provider, response.data.data);
            store.commit("setProvider", Object.assign({}, provider));
          }

          return resolve(provider);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  const loadById = (id) => {
    return api
      .get("/providers/" + id)
      .then((resp) => {
        if (resp.status === 200) {
          store.commit("setProvider", resp.data.data);
          return resp?.data?.data;
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message || t("provider-change-error"));
        return false;
      });
  };

  const logoUrl = computed(() => {
    let baseUrl = api.defaults.baseURL + "provider/logo?";
    let prov = null;

    if (VueCookieNext.isCookieAvailable("pname")) {
      return baseUrl + "name=" + VueCookieNext.getCookie("pname");
    }

    if (
      !VueCookieNext.isCookieAvailable("auth") &&
      (route.query.pid || VueCookieNext.isCookieAvailable("pid"))
    ) {
      let pid;
      if (VueCookieNext.isCookieAvailable("pid")) {
        pid = VueCookieNext.getCookie("pid");
      } else {
        pid = route.query.pid;
      }
      return baseUrl + "id=" + pid + "&t=" + new Date().getTime();
    }

    if (store.getters.getAuthConfiguration) {
      prov = store.getters.getAuthConfiguration;
      return baseUrl + "id=" + prov.id + "&t=" + new Date().getTime();
    }

    if (store.getters.getProvider) {
      prov = store.getters.getProvider;
      return baseUrl + "id=" + prov.id + "&t=" + new Date().getTime();
    }

    return baseUrl + "id=32" + "&t=" + new Date().getTime();
  });


  const loadOAuth = (params, signal) => {
    const defaultParams = {
      limit: 10,
      page: 1,
      sort: "id",
      filter: [],
    };


      return api
          .get("/oauth-providers", {
            signal,
            params: {
              ...defaultParams,
              ...params,
            },
            paramsSerializer: (params) => {
              return qs.stringify(params, { encode: true });
            },
          })
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            }
          })
          .catch((error) => {
            return error;
          });
  };

  const loadOAuthById = (id,signal, params) => {



    return api
        .get("/oauth-providers/" + id, {
          signal,
          params: {
            ...params,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { encode: true });
          },
        })
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          }
        })
        .catch((error) => {
          return error;
        });
  };

  const addOAuth = (product) => {
    return api.post(`/oauth-providers`, product).catch((err) => {
      const error = generateErrorMessage(err?.response?.data);
      toast.error(error || t("provider-oauth-add-error"));
      return false;
    });
  };

  const editOAuth = (product) => {
    return api
        .put(`/oauth-providers/${product.id}`, product)
        .then((resp) => {
          if (resp.status === 200) {
            return resp.data.data;
          }
        })
        .catch((err) => {
          const error = generateErrorMessage(err?.response?.data);
          toast.error(error || t("provider-oauth-edit-error"));
          return false;
        });
  };

  const loadOAuthFieldAccess = (params, signal) => {
    const defaultParams = {
      type: "create",
    };

    return api
        .get("/oauth-providers/field-access", {
          signal,
          params: {
            ...defaultParams,
            ...params,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, {encode: false});
          },
        })
        .then((resp) => {
          if (resp.status === 200) {
            return resp.data.data;
          }
          return false;
        })
        .catch((err) => {
          toast.error(
              err?.response?.data?.message ||
              t("license-product-load-fieldAccess-error")
          );
          return false;
        });
  };

  return {
    provider,
    load,
    logoUrl,
    loadById,
    loadOAuth,
    loadOAuthById,
    addOAuth,
    editOAuth,
    loadOAuthFieldAccess
  };
}
